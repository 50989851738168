import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function Clarity() {
  return (
    <Layout>
      <Helmet title="Clarity | John Mayer" />

      <h1>Clarity</h1>

      <h2>John Mayer</h2>

      <h4>Key: D</h4>

      <h4>Tempo: 92</h4>

      <p>Non-ASCAP</p>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            I <Ch>Gmaj7</Ch>worry I weigh three times my <Ch>Dmaj7</Ch>body
          </p>
          <p>
            I <Ch>Gmaj7</Ch>worry, I throw my fear <Ch>Dmaj7</Ch>around
          </p>
          <p>
            But this <Ch>Gmaj7</Ch>morning there's a <Ch>Dmaj7</Ch>calm I can't
            explain
          </p>
          <p>
            <Ch>Gmaj7</Ch>The rock candy's melted, only <Ch>Dmaj7</Ch>diamonds
            now remain
          </p>
        </Verse>

        <PreChorus>
          <p>
            <Ch>Gmaj7</Ch>Oooooh, <Ch>Dmaj7</Ch>Oooooh
          </p>
        </PreChorus>

        <Verse>
          <p>
            By the <Ch>Gmaj7</Ch>time I recognize this <Ch>Dmaj7</Ch>moment
          </p>
          <p>
            This <Ch>Gmaj7</Ch>moment will be gone<Ch>Dmaj7</Ch>
          </p>
          <p>
            But I will <Ch>Gmaj7</Ch>bend the light pre<Ch>Dmaj7</Ch>tending
          </p>
          <p>
            That it <Ch>Gmaj7</Ch>somehow lingered <Ch>Dmaj7</Ch>on Well all I
            got's...
          </p>
        </Verse>

        <PreChorus>
          <p>
            <Ch>Gmaj7</Ch>Oooooh, <Ch>Dmaj7</Ch>Oooooh
          </p>
        </PreChorus>

        <Chorus>
          <p>
            <b>Gmaj7 F#m7 Bm7</b>
          </p>
          <p>And I will wait to find</p>
          <p>If this will last forever</p>
          <p>And I will wait to find</p>
          <p>If this will last forever</p>
          <p>And I will pay no mind</p>
          <p>When it won't and it won't because</p>
          <p>
            <Ch>Gmaj7</Ch>Can't It just <Ch>Dmaj7</Ch>can't{" "}
          </p>
          <p>
            It's not <Ch>Gmaj7</Ch>supposed to <Ch>Dmaj7</Ch>
          </p>
        </Chorus>

        <Verse>
          <p>
            Was there a <Ch>Gmaj7</Ch>second of time I looked <Ch>Dmaj7</Ch>
            around?
          </p>
          <p>
            Did I <Ch>Gmaj7</Ch>sail through or drop my anchor <Ch>Dmaj7</Ch>
            down?
          </p>
          <p>
            Was <Ch>Gmaj7</Ch>anything enough to kiss the <Ch>Dmaj7</Ch>ground
          </p>
          <p>
            And say I'm here <Ch>Gmaj7</Ch>now and she is here <Ch>Dmaj7</Ch>now
          </p>
        </Verse>

        <PreChorus>
          <p>
            <Ch>Gmaj7</Ch>Oooooh, <Ch>Dmaj7</Ch>Oooooh
          </p>
        </PreChorus>

        <Bridge>
          <p>
            <b>Fmaj7 Bbmaj7 Ebmaj7</b>
          </p>
          <p>So much wasted in the afternoon</p>
          <p>So much sacred in the month of June</p>
          <p>
            <Ch>Dbmaj7</Ch>How bout you <Ch>Dmaj7</Ch>Uh
          </p>
          <p>
            <b>Gmaj7 Dmaj7</b> (solo)
          </p>
        </Bridge>

        <Chorus>
          <p>
            <b>Gmaj7 F#m7 Bm7</b>
          </p>
          <p>And I will wait to find</p>
          <p>If this will last forever, yeah</p>
          <p>And I will wait to find</p>
          <p>That it won't, and it won't, and it won't</p>
          <p>And I will pay no mind</p>
          <p>Worried 'bout no rainy weather, yeah</p>
          <p>And I will waste no time</p>
          <p>Remaining in our lives together</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
